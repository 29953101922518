import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

/**
 * Book Us Button (Web Inquiry)
 */

const bookUs = document.querySelectorAll('.book-us-btn');
const webInquiry = document.querySelector('.web-inquiry-blur');
const close = document.querySelector('#close');
const inquiryContent = document.querySelector('.web-inquiry');

bookUs.forEach((button) => {
  button.addEventListener('click', (e) => {
    webInquiry.classList.add('web-inquiry-open');
    e.stopPropagation(); // Prevent the click from propagating to the document
  });
});

close.addEventListener('click', () => {
  webInquiry.classList.remove('web-inquiry-open');
});

document.addEventListener('click', (e) => {
  if (!inquiryContent.contains(e.target) && !bookUs[0].contains(e.target)) {
    webInquiry.classList.remove('web-inquiry-open');
  }
});

/**
 * Scroll Trigger animations
 */

const packageCard = gsap.utils.toArray('.package-card');
packageCard.forEach((card) => {
  gsap.from('.package-card', {
    opacity: 0,
    x: 400,
  });

  gsap.to('.package-card', {
    scrollTrigger: {
      trigger: '.package-card',
      toggleActions: 'restart none none none',
      start: 'top bottom',
      end: 'bottom top',
    },
    opacity: 1,
    x: 0,
    stagger: {
      each: 0.1,
    },
    duration: 1,
  });
});

gsap.to('.owners', {
  scrollTrigger: {
    trigger: '.owners',
    scrub: 2,
    start: 'top bottom',
    end: 'bottom center',
  },
  y: -200,
  zIndex: 10,
});

gsap.from('.why-handels-wrapper', {
  y: 200,
});

gsap.to('.why-handels-wrapper', {
  scrollTrigger: {
    trigger: '.why-handels',
    scrub: 2,
    start: 'top bottom',
    end: 'bottom center',
  },
  y: -125,
});

gsap.from('.why-text', {
  opacity: 0,
  x: 200,
});

gsap.to('.why-text', {
  scrollTrigger: {
    trigger: '.why-handels',
    toggleActions: 'restart none none none',
    start: 'top 70%',
    end: 'bottom bottom',
  },
  opacity: 1,
  x: 0,
  duration: 1,
});

// Navbar animations
{
  window.addEventListener('scroll', function () {
    var navbar = document.getElementById('navbar');
    if (!navbar.classList.contains('sticky')) {
      navbar.classList.toggle('sticky', window.scrollY > 800);
    } else if (navbar.classList.contains('sticky') && this.window.scrollY < 300) {
      navbar.classList.toggle('sticky');
      navbar.classList.toggle('sticky-gone');
    }

    if (navbar.classList.contains('sticky-gone') && this.window.scrollY < 150) {
      navbar.classList.toggle('sticky-gone');
    }
  });
}

// navbar toggler
{
  const toggleButton = document.getElementsByClassName('nav-toggle-button')[0];
  const navbarLinks = document.getElementsByClassName('nav-links')[0];
  const navbar = document.getElementsByClassName('navbar')[0];

  toggleButton.addEventListener('click', () => {
    navbarLinks.classList.toggle('active');
    navbar.classList.toggle('nav-active');
  });
}

/**
 * Magnetic Truck
 */
const cursor = document.querySelector('.cursor');
const truck = document.querySelector('.truck-lights');
const area = document.querySelector('.area');

const lerp = (x, y, a) => x * (1 - a) + y * a;
window.addEventListener('mousemove', (dets) => {
  gsap.to(cursor, {
    x: dets.clientX - 2,
    y: dets.clientY - 10 + scrollY,
  });
});

area.addEventListener('mousemove', (dets) => {
  const drim = area.getBoundingClientRect();
  const xStart = drim.x;
  const xEnd = drim.x + drim.width;
  const leftRight = gsap.utils.mapRange(xStart, xEnd, 0, 1, dets.clientX);
  const yStart = drim.y;
  const yEnd = drim.y + drim.height;
  const upDown = gsap.utils.mapRange(yStart, yEnd, 0, 1, dets.clientY);
  gsap.to(cursor, {
    scale: 1,
  });
  gsap.to('.truck', {
    x: lerp(-25, 25, leftRight),
    y: lerp(-25, 25, upDown),
  });
  gsap.to('.truck-lights', {
    x: lerp(-25, 25, leftRight),
    y: lerp(-25, 25, upDown),
  });
  gsap.to('.blob', {
    x: lerp(-15, 15, leftRight),
    y: lerp(-15, 15, upDown),
  });
});

area.addEventListener('mouseleave', (dets) => {
  gsap.to(cursor, {
    scale: 0,
  });
  gsap.to('.truck', {
    ease: 'elastic.out(1, 0.2)',
    duration: 2,
    x: 0,
    y: 0,
  });
  gsap.to('.truck-lights', {
    ease: 'elastic.out(1, 0.2)',
    duration: 2,
    x: 0,
    y: 0,
  });
  gsap.to('.blob', {
    ease: 'elastic.out(1, 0.2)',
    duration: 2,
    x: 0,
    y: 0,
  });
});

truck.addEventListener('click', () => {
  const lights = document.querySelector('.truck-lights');
  lights.style.classList.toggle('active');
});

let truckLights = document.getElementsByClassName('truck-lights')[0];

area.addEventListener('click', () => {
  truckLights.classList.toggle('active');
});
// END Magnetic truck ------------------------------------

const scrollers = document.querySelectorAll('.scroller');

// If a user hasn't opted in for recuded motion, then we add the animation
if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
  addAnimation();
}

addAnimation();

function addAnimation() {
  scrollers.forEach((scroller) => {
    // add data-animated="true" to every `.scroller` on the page
    scroller.setAttribute('data-animated', true);

    // Make an array from the elements within `.scroller-inner`
    const scrollerInner = scroller.querySelector('.scroller__inner');
    const scrollerContent = Array.from(scrollerInner.children);

    // For each item in the array, clone it
    // add aria-hidden to it
    // add it into the `.scroller-inner`
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute('aria-hidden', true);
      scrollerInner.appendChild(duplicatedItem);
    });

    // Hover effect
  });
}
